export const BACKEND_API_URL = `${process.env.REACT_APP_BACKEND_API_BASE_URL as string}`;
export const V2_BACKEND_API_URL = `${process.env.REACT_APP_V2_BACKEND_API_BASE_URL as string}`;

// QR
export const QR_CODE_BASE_URL = 'https://api.qrserver.com/v1/create-qr-code/';
export const QR_CODE_SIZE = '?size=150x150';
export const QR_CODE_ENDPOINT = `${QR_CODE_BASE_URL}${QR_CODE_SIZE}&data={MemberUrl}`;

// Members endpoints
export const GET_MEMBERS_ENDPOINT = `${BACKEND_API_URL}/members`;
export const V2_GET_MEMBERS_ENDPOINT = `${V2_BACKEND_API_URL}/members`;
export const POST_MEMBERS_ENDPOINT = `${BACKEND_API_URL}/members`;
export const POST_MEMBERS_ENDPOINT_V2 = `${BACKEND_API_URL}/members/v2`;
export const PATCH_MEMBERS_ENDPOINT = `${BACKEND_API_URL}/members/{MemberId}`;
export const PATCH_MEMBERS_ENDPOINT_V2 = `${BACKEND_API_URL}/members/v2/{MemberId}`;
export const GET_NEW_MEMBER_NUMBER_ENDPOINT = `${BACKEND_API_URL}/members/member-number`;
export const PATCH_MEMBER_ACTIVITY_ENDPOINT = `${BACKEND_API_URL}/members/v2/{MemberId}/activity/{ActivityId}`;
export const DELETE_MEMBER_ACTIVITY_ENDPOINT = `${BACKEND_API_URL}/members/v2/{MemberId}/activity/{ActivityId}`;

// Membership Fees
export const GET_PRODUCTS_MEMBERSHIP_FEES_ENDPOINT = `${BACKEND_API_URL}/products?discriminator=MembershipFee`;
export const GET_PRODUCTS_ACTIVITIES_FEES_ENDPOINT = `${BACKEND_API_URL}/products?discriminator=ActivityFee`;
export const GET_PRODUCTS_BY_ID_ENDPOINT = `${BACKEND_API_URL}/products/{ProductId}`;
export const PATCH_PRODUCTS_ENDPOINT = `${BACKEND_API_URL}/products/{ProductId}`;
export const DELETE_PRODUCTS_ENDPOINT = `${BACKEND_API_URL}/products/{ProductId}`;
export const POST_PRODUCTS_ENDPOINT = `${BACKEND_API_URL}/products`;
export const GET_PRODUCTS_MEMBERSHIP_FEES_BY_MEMBER_ID_ENDPOINT = `${BACKEND_API_URL}/products/membership-fees?memberId={MemberId}`;
export const V2_GET_PRODUCTS_MEMBERSHIP_FEES_BY_MEMBER_ID_ENDPOINT = `${V2_BACKEND_API_URL}/members/{MemberId}/membership-fees`;
export const GET_PRODUCTS_ACTIVITIES_FEES_BY_MEMBER_ID_ENDPOINT = `${BACKEND_API_URL}/products/activities-fees?memberId={MemberId}`;
export const V2_GET_PRODUCTS_ACTIVITIES_FEES_BY_MEMBER_ID_ENDPOINT = `${V2_BACKEND_API_URL}/members/{MemberId}/activity-fees`;

// Users endpoints
export const GET_USERS_ENDPOINT = `${BACKEND_API_URL}/users`;
export const POST_USER_ENDPOINT = `${BACKEND_API_URL}/users`;
export const PATCH_USER_ENDPOINT = `${BACKEND_API_URL}/users/{UserId}`;
export const LOGIN_ENDPOINT = `${BACKEND_API_URL}/users/login`;

// Authorization endpoints
export const GET_MODULES_ENDPOINT = `${BACKEND_API_URL}/authorization/modules`;
export const GET_ROLES_ENDPOINT = `${BACKEND_API_URL}/authorization/roles`;
export const POST_ROLE_ENDPOINT = `${BACKEND_API_URL}/authorization/roles`;

// Sports endpoints
export const GET_SPORTS_ENDPOINT = `${BACKEND_API_URL}/sports`;
export const POST_SPORTS_ENDPOINT = `${BACKEND_API_URL}/sports`;
export const PATCH_SPORTS_ENDPOINT = `${BACKEND_API_URL}/sports/{SportId}`;

// Activities endpoints
export const GET_ACTIVITIES_ENDPOINT = `${BACKEND_API_URL}/activities`;
export const POST_ACTIVITIES_ENDPOINT = `${BACKEND_API_URL}/activities`;

// Values lists endpoints
export const GET_VALUES_LISTS_ENDPOINT = `${BACKEND_API_URL}/values-lists`;
export const POST_VALUES_LISTS_ENDPOINT = `${BACKEND_API_URL}/values-lists`;

// Payments endpoints
export const GET_PAYMENT_ENDPOINT = `${BACKEND_API_URL}/payments/{PaymentId}`;
export const GET_PAYMENTS_ENDPOINT = `${BACKEND_API_URL}/payments`;
export const POST_PAYMENTS_ENDPOINT = `${BACKEND_API_URL}/payments`;
export const V2_POST_PAYMENTS_ENDPOINT = `${V2_BACKEND_API_URL}/payments`;
export const PUT_PAYMENTS_ENDPOINT = `${BACKEND_API_URL}/payments/{PaymentId}`;

// Reports endpoints
export const GET_REPORTS_MEMBER_STATUS_BY_MONTH = `${BACKEND_API_URL}/reports/member-status-by-month?month={Month}&year={Year}`;
export const GET_REPORTS_OWED_MEMBERSHIP_FEES = `${BACKEND_API_URL}/reports/owed-membership-fees`;
export const GET_REPORTS_MEMBERS = `${BACKEND_API_URL}/reports/members`;

// Pages routes
export const LOGIN_PAGE_ROUTE = '/login';
export const HOME_PAGE_ROUTE = '/home';
export const MEMBERS_LIST_PAGE_ROUTE = '/home';

// Misc
export const FORM_SELECT_DEFAULT_OPTION_TEXT = 'Seleccione';
export const FORM_SELECT_ALL_OPTION_TEXT = '(Todos)';
export const BUTTON_CREATE_NEW_LABEL = 'Cargar';
export const BUTTON_CONFIRM_UPDATE_LABEL = 'Guardar';
export const BUTTON_EDIT_LABEL = 'Editar';
export const MEMBER_LIST_SIZE = 20;
export const DEFAULT_SHORT_DATE_FORMAT = 'dd-mm-yyyy';
export const DEFAULT_SHORT_DATE_TIME_FORMAT = 'dd-mm-yyyy HH:MM:ss';
export const DEFAULT_SHORT_ISO_DATE_FORMAT = 'isoDate';

// LookupListIds
export const LOOKUP_LIST_ID_GENDER = 'ef2100bd-78e2-4fd8-a6f7-ed3be655415e';
export const LOOKUP_LIST_ID_MEMBER_TYPE = '719946dd-5573-4ff8-a7dd-a5b2ad4435c4';
export const LOOKUP_LIST_ID_MEMBER_STATUS = '10e6f700-3deb-4cae-a662-70582d571063';
export const LOOKUP_LIST_ID_PAYMENT_METHOD = '89048280-40e2-460d-a468-5e69e327c6a0';
export const LOOKUP_LIST_ID_PAYMENT_VERIFICATION_STATUS = '171d99f0-8eab-414c-b625-85bc86dc1ac8';

// Toast
export const TOAST_DEFAULT_SHOWN_TIME_MS = 5000;
export const TOAST_SUCCESSFUL_OPERATION_TITLE = 'Operación exitosa ✅';
export const TOAST_FAILED_OPERATION_TITLE = 'Operación errónea';
export const TOAST_FAILED_OPERATION_BODY = 'La operación NO finalizó correctamente porque se produjo un error inesperado.';

// Modules
export const MEMBERS_MODULE_NAME = 'Socios';
export const SPORTS_MODULE_NAME = 'Deportes';
export const ACTIVITIES_MODULE_NAME = 'Actividades';
export const SETTINGS_MODULE_NAME = 'Configuración';
export const EVENTS_MODULE_NAME = 'Eventos';
export const MEMBERSHIP_FEES_MODULE_NAME = 'Cuotas sociales';
export const REPORTS_MODULE_NAME = 'Reportes';
export const USERS_MODULE_NAME = 'Usuarios';
export const ROLES_MODULE_NAME = 'Roles';
export const PAYMENTS_MODULE_NAME = 'Pagos';

// Tasks
export const LIST_MEMBER_TASK_NAME = 'Listar socios';
export const VIEW_MEMBER_TASK_NAME = 'Ver socios';
export const ADD_MEMBER_TASK_NAME = 'Cargar socios';
export const EDIT_MEMBER_TASK_NAME = 'Editar socios';

export const VIEW_CREATE_SPORT_TASK_NAME = 'Cargar deportes';

export const LIST_ACTIVITY_TASK_NAME = 'Listar actividades';
export const VIEW_ACTIVITY_TASK_NAME = 'Ver actividades';
export const ADD_ACTIVITY_TASK_NAME = 'Cargar actividades';

export const ADD_ACTIVITY_FEE_TASK_NAME = 'Cargar aranceles de actividades';
export const EDIT_ACTIVITY_FEES_TASK_NAME = 'Editar cuotas sociales';

export const VIEW_VALUE_LIST_TASK_NAME = 'Ver listas de valores';
export const ADD_VALUE_LIST_TASK_NAME = 'Cargar valor en lista de valores';

export const LIST_MEMBERSHIP_FEES_TASK_NAME = 'Listar cuotas sociales';
export const ADD_MEMBERSHIP_FEES_TASK_NAME = 'Cargar cuotas sociales';
export const EDIT_MEMBERSHIP_FEES_TASK_NAME = 'Editar cuotas sociales';

export const GENERATE_REPORTS = 'Generar reportes';
export const GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_BASKET = 'Generar reporte de cuotas adeudadas (básquet)';
export const GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_TENNIS = 'Generar reporte de cuotas adeudadas (tenis)';
export const GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_HOCKEY = 'Generar reporte de cuotas adeudadas (hockey)';
export const GENERATE_REPORTS_OWED_MEMBERSHIP_FEES_FOOTBALL = 'Generar reporte de cuotas adeudadas (fútbol)';
export const GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_BASKET = 'Generar reporte de rendición (básquet)';
export const GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_TENNIS = 'Generar reporte de rendición (tenis)';
export const GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_HOCKEY = 'Generar reporte de rendición (hockey)';
export const GENERATE_REPORTS_MEMBER_STATUS_BY_MONTH_FOOTBALL = 'Generar reporte de rendición (fútbol)';
export const GENERATE_REPORTS_MEMBERS_BASKET = 'Generar reporte de listado de socios (básquet)';
export const GENERATE_REPORTS_MEMBERS_TENNIS = 'Generar reporte de listado de socios (tenis)';
export const GENERATE_REPORTS_MEMBERS_HOCKEY = 'Generar reporte de listado de socios (hockey)';
export const GENERATE_REPORTS_MEMBERS_FOOTBALL = 'Generar reporte de listado de socios (fútbol)';


export const LIST_USERS_TASK_NAME = 'Listar usuarios';
export const ADD_USERS_TASK_NAME = 'Cargar usuarios';

export const ADD_ROLES_TASK_NAME = 'Cargar roles';
export const LIST_ROLES_TASK_NAME = 'Ver roles';

export const LIST_PAYMENTS_TASK_NAME = 'Ver y verificar pagos';

// Products discriminators
export const MEMBERSHIP_FEE_DISCRIMINATOR = 'MembershipFee';
export const ACTIVITY_FEE_DISCRIMINATOR = 'ActivityFee';

// Role
export const MEMBER_ROLE_ID = '696d0d76-32cb-4756-a4ea-0d102d333d19';

// Payments verification status
export const SUCCESSFULLY_VERIFIED_STATUS_ID = '1276e516-edd0-4105-9769-18f38883aadf';

export const SPORT_TENNIS = 'fd837bc0-2595-492f-9563-6749e9542714';
export const SPORT_BASKET = 'ff6f1330-2e5b-4584-b9c6-2aa817e4f846';
export const SPORT_FOOTBALL = '1a11d75b-1c86-49d1-ab71-5fb93add28fb';
export const SPORT_HOCKEY = '2ff5d745-ddeb-48bb-8701-f63bd6008b02';
