import {
  DELETE_MEMBER_ACTIVITY_ENDPOINT,
  GET_MEMBERS_ENDPOINT,
  GET_NEW_MEMBER_NUMBER_ENDPOINT, PATCH_MEMBER_ACTIVITY_ENDPOINT,
  PATCH_MEMBERS_ENDPOINT_V2,
  POST_MEMBERS_ENDPOINT_V2, V2_GET_MEMBERS_ENDPOINT
} from "../constants";
import {AxiosError, AxiosResponse} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {ActivityAction, MemberDto} from "../models/member/member-dto";
import {MemberActivityDto} from "../models/dtos/member-activity-dto";
import {HttpRequestResponse} from "../models/axios/http-request-response";
import {getEndpointResponse} from "../models/endpoint-response";

export const makeGetMembersCall = async(pageSize: number = 10, currentPage: number = 1, includeInactive: boolean = false, memberSearch: MemberSearch | null = null): Promise<AxiosResponse | null> => {
  let options = `?pageSize=${pageSize}&currentPage=${currentPage}&includeInactive=${includeInactive}`;
  if (memberSearch?.documentNumber) {
    options += `&searchFilter=documentNumber&searchByFilter=${memberSearch.documentNumber}`;
  }
  if (memberSearch?.memberNumber) {
    options += `&searchFilter=memberNumber&searchByFilter=${memberSearch.memberNumber}`;
  }
  if (memberSearch?.fullName) {
    options += `&searchFilter=fullName&searchByFilter=${memberSearch.fullName}`;
  }

  const response = await axiosPrivate.get(V2_GET_MEMBERS_ENDPOINT.concat(options));
  if (response.status === 200) {
    return response;
  } else {
    console.log(
      `Error while trying to get members: ${response.statusText}`
    );
    return null;
  }
};

export const makeGetMemberByIdCall = async(memberId: string): Promise<AxiosResponse | null> => {
  const response = await axiosPrivate.get(`${GET_MEMBERS_ENDPOINT}/${memberId}`);
  if (response.status === 200) {
    return response;
  } else {
    console.log(
      `Error while trying to get MemberId ${memberId}: ${response.statusText}`
    );
    return null;
  }
};

export const makePostMembersCallV2 = async (member: MemberDto, file?: File): Promise<AxiosResponse | null> => {
  const response = await axiosPrivate.postForm(POST_MEMBERS_ENDPOINT_V2, {
    data: JSON.stringify(member),
    image: file
  });
  if (response.status === 201) {
    return response;
  } else {
    console.log(
      `Error while trying to create member: ${response.statusText}`
    );
    return null;
  }
};

export const makePatchMembersCallV2 = async (member: MemberDto, file?: File): Promise<AxiosResponse | null> => {
  if (!member.id) { return null }

  if (member.contact) {
    member.contact.isCurrent = true;
  }

  const url = PATCH_MEMBERS_ENDPOINT_V2.replace('{MemberId}', member.id);
  const response = await axiosPrivate.patchForm(url, {
    data: JSON.stringify(member),
    image: file
  });
  if (response.status === 200) {
    return response;
  } else {
    console.log(
      `Error while trying to update member: ${response.statusText}`
    );
    return null;
  }
};

export const makePatchMemberActivityCall = async (memberId: string, memberActivityDto: MemberActivityDto): Promise<HttpRequestResponse<null>> => {
  if (!memberId || !memberActivityDto) { return Promise.reject("Missing input") }

  const url = PATCH_MEMBER_ACTIVITY_ENDPOINT
    .replace('{MemberId}', memberId)
    .replace('{ActivityId}', memberActivityDto.activityId);

  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.patch(url, memberActivityDto);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makeDeleteMemberActivityCall = async (memberId: string, activityId: string): Promise<AxiosResponse | null> => {
  if (!memberId || !activityId) { return null }

  const url = DELETE_MEMBER_ACTIVITY_ENDPOINT
    .replace('{MemberId}', memberId)
    .replace('{ActivityId}', activityId);
  const response = await axiosPrivate.delete(url);
  if (response.status === 204) {
    return response;
  } else {
    console.log(
      `Error while trying to update member: ${response.statusText}`
    );
    return null;
  }
};

export const makeGetNewMemberNumberCall = async(): Promise<{ MemberNumber: number } | null> => {
  const response = await axiosPrivate.get(GET_NEW_MEMBER_NUMBER_ENDPOINT);
  if (response.status === 200) {
    return response.data as { MemberNumber: number };
  } else {
    console.log(
      `Error while trying to get new member id: ${response.statusText}`
    );
    return null;
  }
};

export interface MemberSearch {
  documentNumber?: number;
  memberNumber?: number;
  fullName?: string;
}
